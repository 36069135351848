<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="_fields"
      :liveSearching="true"
      :showFields="_showFields"
      :infiniteScroll="true"
      :inRowBtnRead="false"
      :updateHideFields="updateHideFields"
      :customTableActions="customTableActions"
      @reset-password="$_onResetPassword($event)"
    ></WsCrud>
    <WsDialogAdminPasswordUpdate ref="WsDialogAdminPasswordUpdate"></WsDialogAdminPasswordUpdate>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "後台使用者管理",
    };
  },

  methods: {
    $_onResetPassword($event) {
      this.$refs.WsDialogAdminPasswordUpdate.open($event.item.id);
    },
  },

  data() {
    return {
      modelName: "admin",
      updateHideFields: ["password"],
      customTableActions: [
        {
          icon: "icon-md-vpn-key",
          emit: "reset-password",
          tooltip: this.$t("更改密碼"),
        },
      ],
    };
  },

  computed: {
    _showFields() {
      const _showFields = ["id", "name", "email", "country_code"];
      if (this.$config.auth.admin_group) {
        if (this.$config.auth.admin_blur) {
          _showFields.push("cmser_groups");
        } else {
          _showFields.push("admin_groups");
        }
      }
      return _showFields;
    },
    _fields() {
      const _fields = {
        ...this.$store.state.stone_model[this.modelName].fields,
      };
      if (!this.$config.auth.admin_group) {
        delete _fields["admin_groups"];
        delete _fields["cmser_groups"];
      }
      return _fields;
    },
  },
};
</script>